import React, { useState } from "react";
import { FaGoogle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie'; 
import './Login.css';

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://backend.smartcapital.co.in/user/loginUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        const expiresIn = new Date();
        expiresIn.setTime(expiresIn.getTime() + 11 * 60 * 60 * 1000); 
        Cookies.set('userToken', data.userToken, { expires: expiresIn });
        Cookies.set('userId', data.userData.userId, { expires: expiresIn });
             
        navigate('/Dashboard');

        // Swal.fire({
        //   icon: 'success',
        //   title: 'Login Successful',
        //   text: 'Welcome back to the dashboard!',
        // }).then(() => {
        // });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: data.message || 'Invalid credentials. Please try again.',
        });
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: 'error',
        title: 'An Error Occurred',
        text: 'Please try again later.',
      });
    }
  };


  return (
    <div className="login-container">
      <div className="form-section">
        <h1 className="heading">Join Smart Capital</h1>
        <h3 className="sub-heading">Sign Up For Free</h3>

        <div className="form-wrapper">
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="input-full"
            />

            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="input-full"
            />
            <div className="submit-button-wrapper">
              <button type="submit" className="submit-button">
                Get Started
              </button>
            </div>
          </form>
        </div>

        <div className="or-section">OR</div>

        <div className="social-buttons-wrapper">
          <button className="social-button google-button">
            <FaGoogle className="google-icon" style={{ marginRight: '10px' }} />
            Sign in with Google
          </button>
        </div>
        <div className="account-footer">
          Already have an account? <a href="/signup">signup</a>
        </div>
      </div>

      <div className="image-section-cover">
      </div>
    </div>
  );
};

export default Login;

