import React, { useContext, useEffect, useState, useRef } from "react";
import {Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton, AppBar, Toolbar} from "@mui/material";
import { ThemeContext } from "../../theme-context";
import axios from "axios";
import './Stock.css';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { useWebSocketData } from "../../Context/WebSocketContext";  
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';

const chartData1 = [
  { name: 'Jan', value: 4000 },
  { name: 'Feb', value: 3000 },
  { name: 'Mar', value: 2000 },
  { name: 'Apr', value: 2780 },
  { name: 'May', value: 1890 },
  { name: 'Jun', value: 2390 },
];

const chartData2 = [
  { name: 'Q1', value: 3000 },
  { name: 'Q2', value: 5000 },
  { name: 'Q3', value: 4200 },
  { name: 'Q4', value: 5700 },
];

const Stock = () => {
  const { theme } = useContext(ThemeContext);
  const [stockData, setStockData] = useState([]);
  const [loadingGainersLosers, setLoadingGainersLosers] = useState(true);
  const [loadingActiveStocks, setLoadingActiveStocks] = useState(true);
  const [topGainers, setTopGainers] = useState([]);
  const [topLosers, setTopLosers] = useState([]);
  const [mostActiveStocks, setMostActiveStocks] = useState([]);
  const { socketData } = useWebSocketData(); 
  const navigate = useNavigate();

  const [isDragging, setIsDragging] = useState(false);
  const [startDrag, setStartDrag] = useState(0);
  const [offset, setOffset] = useState(0);
  const cardContainerRef = useRef(null);

  const [showTopGainers, setShowTopGainers] = useState(true); 
  const [selectedExchange, setSelectedExchange] = useState("NSE");

  const cardWidth = 232;
  const cardHeight = 80;

  const colors = {
    primary: {
      400: `var(--primary-${theme}-400)`,
      500: `var(--primary-${theme}-500)`,
    },
    greenAccent: {
      500: `var(--greenAccent-${theme}-500)`,
      600: `var(--greenAccent-${theme}-600)`, 
    },
    redAccent: {
      500: `var(--red-${theme}-500)`,
    },
    grey: {
      100: `var(--grey-${theme}-100)`,
      300: `var(--grey-${theme}-300)`,
    },
  };

  const fetchTopGainersAndLosers = async () => {
    setLoadingGainersLosers(true);
    try {
      const apiUrl = `${config.apiBaseUrl}/stock/topLoserAndGainer`;
      const response = await axios.get(apiUrl);
      const { topGainers, topLosers } = response.data;
      setTopGainers(topGainers.slice(0, 5));
      setTopLosers(topLosers.slice(0, 5));
    } catch (error) {
      console.error('Error fetching top gainers and losers:', error);
    } finally {
      setLoadingGainersLosers(false);
    }
  };

  const fetchStockData = async () => {
    try {
      const apiUrl = `${config.apiBaseUrl}/admin/getDashCard`;
      const response = await axios.get(apiUrl);
      const formattedData = response.data.dashboardData.map(stock => ({
        name: stock.name || stock.tradingsymbol || "Loading...",
        instrument_token: stock.instrument_token,
        last_price: stock.last_price !== null ? stock.last_price : 0,
        net_change: stock.net_change !== null ? stock.net_change : 0,
        exchange: stock.exchange || "N/A",  
      }));
      setStockData(formattedData);
    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
  };

  const fetchMostActiveStocks = async () => {
    setLoadingActiveStocks(true);

    try {
      const apiUrl = `${config.apiBaseUrl}/stock/mostActiveStock`;
      const response = await axios.get(apiUrl);
      const { mostActiveStocks } = response.data;
      setMostActiveStocks(mostActiveStocks.slice(0, 11));
    } catch (error) {
      console.error('Error fetching most active stocks:', error);
    } finally {
      setLoadingActiveStocks(false);
    }
  };

  useEffect(() => {
    fetchTopGainersAndLosers();
    fetchStockData();
    fetchMostActiveStocks();
  }, []); 

  const renderShimmerRows = () => (
    [...Array(5)].map((_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Skeleton variant="text" width="80%" height={40} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height={40} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height={40} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height={40} />
        </TableCell>
      </TableRow>
    ))
  );

  const calculatePointsChange = (lastPrice, netChange) => {
    return (lastPrice * (netChange / 100)).toFixed(2); 
  };

  const formatNetChange = (netChange) => {
    return `${parseFloat(netChange).toFixed(2)}%`;
  };

  const handleClick = (exchange) => {
    setSelectedExchange(exchange);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartDrag(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const delta = e.clientX - startDrag;
      setOffset(delta);
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      const cardShift = Math.round(offset / (cardWidth + 20)); 
      setOffset(cardShift * (cardWidth + 20));
      setIsDragging(false);
    }
  };

  return (
    <Box m="20px">
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="auto" gap="20px">
        {/* ROW 1 */}
        <Box gridColumn="span 12" display="flex" alignItems="center" justifyContent="space-between" mb={2} mt={4}>
          <Box display="flex" overflow="hidden" sx={{ width: 'calc(100% - 60px)' }} ref={cardContainerRef} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseDown={handleMouseDown}>
            <Box display="flex" transition="transform 0.3s ease" style={{ transform: `translateX(${offset}px)` }}>
              {stockData.map((stock, index) => {
                const updatedStock = socketData[stock.instrument_token] || {};
                const lastPrice = updatedStock.last_price !== undefined ? updatedStock.last_price : stock.last_price;
                const netChange = updatedStock.net_change !== undefined ? updatedStock.net_change : stock.net_change;
                const pointsChange = calculatePointsChange(lastPrice, netChange);
                const percentageChange = ((pointsChange / lastPrice) * 100).toFixed(2); 

                const themeBackgroundColor = theme === "light" ? colors.primary[400] : colors.primary[400];  

                const changeColor = pointsChange > 0 ? colors.greenAccent[500] : colors.redAccent[500];

                return (
                  <Box
                    key={index}
                    mx={1}
                    backgroundColor={themeBackgroundColor} 
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexShrink={0}
                    width={cardWidth}
                    p={2}
                    borderRadius={2}
                    height={cardHeight}
                    sx={{ border: '1px solid grey', cursor: 'pointer' }}
                    onClick={() => navigate(`/StockChart/${stock.instrument_token}`)}
                  >
                    <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                      <Typography 
                        variant="h6" 
                        fontWeight="600" 
                        color={theme === "light" ? colors.grey[100] : "white"}  
                        sx={{ 
                          overflow: 'hidden', 
                          textOverflow: 'ellipsis', 
                          whiteSpace: 'nowrap', 
                          maxWidth: '120px' 
                        }}
                      >
                        {stock.name}
                      </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" width="100%" my={1}>
                      <Typography variant="body2" color={theme === "light" ? colors.grey[100] : "white"} fontWeight="500">
                        {stock.exchange}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color={changeColor} 
                        fontWeight="500"
                      >
                        {pointsChange > 0 ? `↑ ${pointsChange}` : `↓ ${pointsChange}`}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Typography variant="body2" color={theme === "light" ? colors.grey[100] : "white"} fontWeight="400">
                        {lastPrice}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color={changeColor} 
                        fontWeight="400"
                      >
                        ({pointsChange > 0 ? `+${percentageChange}%` : `${percentageChange}%`})
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>


        <Box gridColumn="span 12">
          <Box sx={{ width: "100%" }}>
            <AppBar position="sticky" sx={{ backgroundColor: colors.primary[400], zIndex: 1201, boxShadow: 'none', borderRadius: '10px' }}>
              <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box
                  sx={{
                    backgroundColor: showTopGainers ? '#4CAF50' : '#F8A1A1',
                    borderRadius: '6px',
                    padding: '8px 12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="body2" color={colors.grey[100]}>
                    {showTopGainers ? "Top Gainers" : "Top Losers"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#4CAF50', 
                    borderRadius: '8px',
                    padding: '5px 10px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {['NSE', 'BSE'].map((exchange) => (
                    <Box
                      key={exchange}
                      onClick={() => handleClick(exchange)}
                      sx={{
                        padding: '8px 12px',
                        margin: '0 5px',
                        cursor: 'pointer',
                        backgroundColor: selectedExchange === exchange ? '#F8F8F8' : '#4CAF50',
                        borderRadius: '6px',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                          backgroundColor: '#E0E0E0',
                        },
                      }}
                    >
                      <Typography variant="body2" color={selectedExchange === exchange ? '#000' : '#fff'}>
                        {exchange}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Toolbar>
            </AppBar>
            {/* Table */}
            <Box gridColumn="span 12" display="flex" flexDirection="column" gap={2} sx={{ paddingTop: '20px' }}>
            <Box backgroundColor={colors.primary[400]} p={2} borderRadius={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box display="flex" gap={2}>
                  <Typography
                    variant="h6"
                    color={showTopGainers ? colors.greenAccent[500] : colors.grey[100]}
                    onClick={() => setShowTopGainers(true)}
                    sx={{ cursor: "pointer" }}
                  >
                    Top Gainers
                  </Typography>
                  <Typography
                    variant="h6"
                    color={!showTopGainers ? colors.redAccent[500] : colors.grey[100]}
                    onClick={() => setShowTopGainers(false)}
                    sx={{ cursor: "pointer" }}
                  >
                    Top Losers
                  </Typography>
                </Box>
              </Box>
              <TableContainer component={Paper} elevation={0} sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ 
                        color: theme === "light" ? colors.grey[500] : "white",
                        position: 'relative',
                        borderBottom: 'none',
                        lineHeight: '1px',
                      }}>
                        <strong>Trading Symbol</strong>
                      </TableCell>
                      <TableCell sx={{ 
                        color: theme === "light" ? colors.grey[500] : "white", 
                        position: 'relative',
                        borderBottom: 'none',
                        lineHeight: '1px', 
                      }}>
                        <strong>Company Name</strong>
                      </TableCell>
                      <TableCell sx={{ 
                        color: theme === "light" ? colors.grey[500] : "white", 
                        position: 'relative',
                        borderBottom: 'none',
                        lineHeight: '1px',
                      }}>
                        <strong>Current Price</strong>
                      </TableCell>
                      <TableCell sx={{ 
                        color: theme === "light" ? colors.grey[500] : "white", 
                        position: 'relative',
                        borderBottom: 'none',
                        lineHeight: '1px',
                      }}>
                        <strong>Percentage</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingGainersLosers ? renderShimmerRows() : (showTopGainers ? topGainers : topLosers).map((stock, index) => {
                      const isGainer = showTopGainers;
                      const backgroundColor = isGainer 
                        ? colors.greenAccent[100] 
                        : colors.redAccent[100]; 
                      const dotColor = isGainer ? colors.greenAccent[500] : colors.redAccent[500];
                      
                      const percentageSign = isGainer ? '+' : ''; 

                      return (
                        <TableRow 
                          key={index} 
                          sx={{
                            cursor: 'pointer', 
                            '& td, & th': {
                              borderBottom: 'none', 
                            }
                          }} 
                          onClick={() => navigate(`/StockChart/${stock.instrument_token}`)}
                        >
                          <TableCell sx={{ 
                            color: theme === "light" ? colors.grey[900] : "white", 
                            position: 'relative',
                            lineHeight: '1',
                          }}>
                            {stock.tradingsymbol}
                            <Box 
                              sx={{
                                position: 'absolute',
                                top: 0,
                                right: '5%',
                                width: '90%',
                                height: '1px',
                                backgroundColor: theme === "light" ? "grey" : "white" 
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ 
                            color: theme === "light" ? colors.grey[900] : "white", 
                            position: 'relative',
                            lineHeight: '1', 
                          }}>
                            {stock.instrument_name}
                            <Box 
                              sx={{
                                position: 'absolute',
                                top: 0,
                                right: '5%',
                                width: '90%',
                                height: '1px',
                                backgroundColor: theme === "light" ? "grey" : "white" 
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ 
                            color: theme === "light" ? colors.grey[900] : "white", 
                            position: 'relative',
                            lineHeight: '1',
                          }}>
                            {stock.last_price}
                            <Box 
                              sx={{
                                position: 'absolute',
                                top: 0,
                                right: '5%',
                                width: '90%',
                                height: '1px',
                                backgroundColor: theme === "light" ? "grey" : "white"
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ 
                            position: 'relative',
                            lineHeight: '1', 
                          }}>
                            <Box 
                              sx={{
                                backgroundColor: backgroundColor,
                                padding: '4px 8px', 
                                borderRadius: '12px', 
                                color: isGainer ? colors.greenAccent[500] : colors.redAccent[500], 
                                display: 'inline-flex', 
                                alignItems: 'center', 
                                gap: '4px', 
                                border: '1px solid', 
                                borderColor: isGainer ? colors.greenAccent[500] : colors.redAccent[500], 
                              }}
                            >
                              <Box 
                                sx={{
                                  width: '8px', 
                                  height: '8px', 
                                  borderRadius: '50%', 
                                  backgroundColor: dotColor,
                                }} 
                              />
                              {percentageSign}{formatNetChange(stock.percentageChange)}
                            </Box>
                            <Box 
                              sx={{
                                position: 'absolute',
                                top: 0,
                                right: '5%',
                                width: '90%',
                                height: '1px',
                                backgroundColor: theme === "light" ? "grey" : "white" 
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          </Box>
        </Box>

        <Box gridColumn="span 6" backgroundColor={colors.primary[400]} overflow="auto" height="auto" p={2} borderRadius={2}>
          <Box gridColumn="span 6" backgroundColor={colors.primary[400]} overflow="auto" height="auto" p={2} display="flex" alignItems="stretch" flexDirection={{ xs: "column", sm: "row" }}>
            <Box
              sx={{
                backgroundColor: showTopGainers ? '#4CAF50' : '#F8A1A1',
                borderRadius: '6px',
                padding: '8px 12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 'auto',
                maxWidth: '140px',
                height: '100%',
                marginBottom: { xs: '10px', sm: '0' },  
              }}
            >
              <Typography color={colors.grey[100]} variant="h5" fontWeight="600" sx={{ fontSize: { xs: '16px', sm: '18px' } }}>
                Active Stocks
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: '#4CAF50',
                borderRadius: '6px',
                padding: '8px 12px',
                display: 'flex',
                alignItems: 'center',
                width: 'auto',
                maxWidth: '200px',
                marginLeft: 'auto',
                height: '100%',
                flexWrap: 'wrap', 
                justifyContent: { xs: 'center', sm: 'flex-end' }, 
              }}
            >
              {['NSE', 'BSE'].map((exchange) => (
                <Box
                  key={exchange}
                  onClick={() => setSelectedExchange(exchange)}
                  sx={{
                    padding: '8px 12px',
                    margin: '0 5px',
                    cursor: 'pointer',
                    backgroundColor: selectedExchange === exchange ? '#F8F8F8' : '#4CAF50',
                    borderRadius: '6px',
                    transition: 'background-color 0.3s',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      backgroundColor: '#E0E0E0',
                    },
                  }}
                >
                  <Typography variant="body2" color={selectedExchange === exchange ? '#000' : '#fff'}>
                    {exchange}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <TableContainer component={Paper} elevation={0} sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', marginTop: '10px' }}>
            <Table sx={{ borderCollapse: 'collapse' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: theme === "light" ? "black" : "white", border: 'none', position: 'relative', py: 0.5, pt: 1 }}>
                    <strong>Trading Symbol</strong>
                  </TableCell>
                  <TableCell sx={{ color: theme === "light" ? "black" : "white", border: 'none', position: 'relative', py: 0.5, pt: 1 }} colSpan={2}>
                    <strong>Price & Volume</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingActiveStocks ? renderShimmerRows() : mostActiveStocks.map((stock, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      cursor: 'pointer',
                      '&:last-child td, &:last-child th': { border: 'none' },
                    }}
                    onClick={() => navigate(`/StockChart/${stock.instrument_token}`)}
                  >
                    <TableCell sx={{ color: theme === "light" ? "black" : "white", border: 'none', position: 'relative', py: 0.5, pt: 1 }}>
                      {stock.tradingsymbol}
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: '5%',
                          width: '90%',
                          height: '1px',
                          backgroundColor: theme === "light" ? "grey" : "white"
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ color: theme === "light" ? "black" : "white", border: 'none', position: 'relative', py: 0.5, pt: 1 }}>
                      <div>₹{stock.last_price}</div>
                      <div>{stock.volume}</div>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: '5%',
                          width: '90%',
                          height: '1px',
                          backgroundColor: theme === "light" ? "grey" : "white"
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box gridColumn="span 6" display="flex" flexDirection="column" gap={2}>
          <Box
            sx={{
              flex: 1,
              backgroundColor: colors.primary[400],
              borderRadius: '6px',
              padding: 2,
              boxShadow: 2,
            }}
          >
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={chartData1}>
                <CartesianGrid stroke="none" />
                <XAxis dataKey="name" />
                <YAxis />
                <Legend wrapperStyle={{ visibility: 'hidden' }} />
                <Bar dataKey="value" fill="#2196F3" activeShape={false} />
              </BarChart>
            </ResponsiveContainer>
          </Box>

          <Box
            sx={{
              flex: 1,
              backgroundColor: colors.primary[400],
              borderRadius: '6px',
              padding: 2,
              boxShadow: 2,
            }}
          >
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={chartData2}>
                <CartesianGrid stroke="none" />
                <XAxis dataKey="name" />
                <YAxis />
                <Legend wrapperStyle={{ visibility: 'hidden' }} />
                <Bar dataKey="value" fill="#2196F3" activeShape={false} />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>

        </Box>
      </Box>
  );
};

export default Stock;
