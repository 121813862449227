import './App.css';
import { ThemeProvider, ThemeContext } from './theme-context';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Register from './components/Login/Register';
import Login from './components/Login/Login';
import Sidebar from './components/Sidebar/Sidebar';
import TopBar from './components/TopBar/TopBar';
import { useState, useContext, useEffect } from 'react';
import Footer from './components/Footer/Footer';
import Wallet from './components/Wallet/Wallet';
import Watchlist from './components/Favorite/Watchlist';
import Profile from './components/Profile/Profile';
import Stock from './components/Stock/Stock';
import LandingPage from './components/LandingPage/LandingPage';
import StockTable from './components/AllStock/AllStock';
import StockChart from './components/StockDetails/StockDetails';
import { WebSocketProvider } from './Context/WebSocketContext';
import Cookies from 'js-cookie'; 

function App() {
  return (
    <ThemeProvider>
      <WebSocketProvider>
        <div className="App">
          <Router>
            <MainApp />
          </Router>
        </div>
      </WebSocketProvider>
    </ThemeProvider>
  );
}

function MainApp() {
  const location = useLocation();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { theme } = useContext(ThemeContext);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const isAuthPage = ['/', '/signin', '/signup'].includes(location.pathname);

  useEffect(() => {
    const authToken = Cookies.get('userToken');  
    
    if (!authToken && !isAuthPage) {
      navigate('/signin');
    }
  }, [navigate, isAuthPage]);

  return (
    <>
      {isAuthPage ? (
        <div className="auth-layout">
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/signup' element={<Register />} />
            <Route path='/signin' element={<Login />} />
          </Routes>
        </div>
      ) : (
        <div className='layout-container'>
          <Sidebar isOpen={sidebarOpen} onToggle={toggleSidebar} />
          <div className='main-content'>
            <TopBar onToggle={toggleSidebar} />
            <div className='content' style={{ backgroundColor: theme === 'dark' ? 'black' : '#f9f9f9' }}>
              <Routes>
                <Route path='/dashboard' element={<Stock />} />
                <Route path='/wallet' element={<Wallet />} />
                <Route path='/Watchlist' element={<Watchlist />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/StockTable' element={<StockTable />} />
                <Route path='/StockChart/:instrumentToken' element={<StockChart />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </div>
      )}
      {!isAuthPage && <Footer />}
    </>
  );
}

function NotFound() {
  return <h2>404 - Page Not Found</h2>;
}

export default App;
