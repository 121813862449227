import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../../theme-context';
import { FaWallet } from 'react-icons/fa'; 
import axios from 'axios';
import Cookies from 'js-cookie'; 
import Swal from 'sweetalert2';
import config from '../../config';
import './Wallet.css';

const Wallet = () => {
  const { theme } = useContext(ThemeContext);
  
  const [walletData, setWalletData] = useState({
    total_balance: 0,
    withdraw_balance: 0,
    hold_balance: 0,
  });

  const [transactionData, setTransactionData] = useState(null); 
  const [addMoneyAmount, setAddMoneyAmount] = useState(''); 

  useEffect(() => {
    const token = Cookies.get('userToken'); 
    const userId = Cookies.get('userId'); 

    if (token && userId) {
      const apiUrl = `${config.apiBaseUrl}/user/getWallet`;
      axios.get(apiUrl, {
        headers: {
          'x-access-token': token,
          'user-id': userId,
        }
      })
        .then(response => {
          setWalletData(response.data.wallet); 
        })
        .catch(error => {
          console.error("Error fetching wallet data:", error);
        });
    
      const apiUrlTransaction = `${config.apiBaseUrl}/user/getTransactionHistory`;
      axios.get(apiUrlTransaction, {
        headers: {
          'x-access-token': token,
          'user-id': userId,
        }
      })
        .then(response => {
          setTransactionData(response.data.wallet); 
        })
        .catch(error => {
          console.error("Error fetching transaction data:", error);
        });
    } else {
      console.error('Token or User ID not found in cookies');
    }
  }, []); 

  const handleAddMoney = async () => {
    const token = Cookies.get('userToken'); 
    const userId = Cookies.get('userId'); 

    if (!addMoneyAmount || isNaN(addMoneyAmount) || Number(addMoneyAmount) <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter a valid amount.',
      });
      return;
    }

    try {
      const apiUrl = `${config.apiBaseUrl}/user/addBalance`
      const response = await axios.post(apiUrl, 
        {
          amount: Number(addMoneyAmount),
          userId : userId
        },
        {
          headers: {
            'x-access-token': token,
          }
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Money added successfully!',
        });
        setWalletData(prevData => ({
          ...prevData,
          total_balance: prevData.total_balance + Number(addMoneyAmount),
        }));
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to add money.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Unable to add money. Please try again later.',
      });
    } finally {
      setAddMoneyAmount(''); 
    }
  };

  return (
    <div className={`wallet-container ${theme}`}>
      <div className="wallet-main">
        <div className="watchlist">
          <h2>Wallet</h2>
          <div className="wallet-balance">
            <FaWallet />
            <span>Wallet Balance</span>
            <h1>{walletData.total_balance ? walletData.total_balance.toFixed(2) : "0.00"}</h1>
          </div>
          <div className="investment-info">
            <div className="info-box blue">
              <p>Total Balance</p>
              <h3>{walletData.total_balance ? walletData.total_balance.toFixed(2) : "0.00"}</h3>
            </div>
            <div className="info-box yellow">
              <p>Withdraw Balance</p>
              <h3>{walletData.withdraw_balance ? walletData.withdraw_balance.toFixed(2) : "0.00"}</h3>
            </div>
            <div className="info-box green">
              <p>Hold Balance:</p>
              <h3>{walletData.hold_balance ? walletData.hold_balance.toFixed(2) : "0.00"}</h3>
            </div>
          </div>
        </div>

        {/* Transaction History Section */}
        <div className="transaction-history">
          <h2>Transaction History</h2>
          <table>
            <thead>
              <tr>
                <th className="invoice">Invoice</th>
                <th className="amount">Amount</th>
                <th className="hide-on-mobile">Type</th> 
                <th className="status">Status</th>
                <th className="hide-on-mobile">Description</th> 
              </tr>
            </thead>
            <tbody>
              {transactionData ? (
                transactionData.map(transaction => (
                  <tr key={transaction.transactionId}>
                    <td className="invoice">{transaction.transactionId}</td>
                    <td className="amount">{transaction.amount ? transaction.amount.toFixed(2) : "0.00"}</td>
                    <td className="hide-on-mobile">{transaction.type}</td> 
                    <td className="status">{transaction.status}</td>
                    <td className="hide-on-mobile">{transaction.description}</td> 
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No transaction data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="wallet-sidebar">
        <h3>Add Money</h3> 
        <form
          onSubmit={e => {
            e.preventDefault(); 
            handleAddMoney();
          }}
        >
          <label>Amount</label>
          <input
            type="text"
            placeholder="Enter amount"
            value={addMoneyAmount}
            onChange={e => setAddMoneyAmount(e.target.value)}
          />
          <button type="submit">Add</button>
        </form>
      </div>
    </div>
  );
};

export default Wallet;

