import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../../theme-context';
import { Button, Box, Grid,Avatar  } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie'; 
import Swal from 'sweetalert2'; 
import { CheckCircle, ErrorOutline, Person as PersonIcon } from '@mui/icons-material';
import './Profile.css';
import imageCompression from 'browser-image-compression';
import config from '../../config';

const Profile = () => {
  const { theme } = useContext(ThemeContext);

  const [generalInfo, setGeneralInfo] = useState({
    firstName: '',
    lastName: '',
    userName: '', 
    email: '',
    countryCode: '+91',
    contact: '', 
  });
  const [kycInfo, setKycInfo] = useState({
    aadharNumber: '',
    panNumber: '',
    aadharFrontImage: null,
    aadharBackImage: null,
    panFrontImage: null,
    panBackImage: null,
  });
  const [isKyc, setIsKyc] = useState(false);

  useEffect(() => {
    const userId = Cookies.get('userId');
    const accessToken = Cookies.get('userToken'); 

    if (userId && accessToken) {
      const apiUrl = `${config.apiBaseUrl}/user/getProfile`;
      axios.get(apiUrl, {
        headers: {
          'user-id': userId,
          'x-access-token': accessToken,
        },
      })
      .then(response => {
        const { first_name, last_name, user_name, email, contact, isKYC } = response.data.userData;

        setGeneralInfo({
          firstName: first_name,
          lastName: last_name,
          userName: user_name, 
          email,
          contact: contact || '', 
        });

        setIsKyc(isKYC); 
      })
      .catch(error => {
        console.error("Error fetching profile data:", error);
      });
    }
  }, []); 

  const handleGeneralInfoChange = (e) => {
    const { name, value } = e.target;
    setGeneralInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleKycChange = async (e) => {
    const { name, value, files } = e.target;
    
    if (files) {
      const file = files[0];
      const options = {
        maxSizeMB:  0.2, 
        maxWidthOrHeight: 600, 
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options); 
        setKycInfo((prev) => ({ ...prev, [name]: compressedFile }));
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    } else {
      setKycInfo((prev) => ({ ...prev, [name]: value }));
    }
  };

  const saveGeneralInfo = () => {
    const userId = Cookies.get('userId');
    const accessToken = Cookies.get('userToken');

    if (userId && accessToken) {
      const payload = {
        first_name: generalInfo.firstName,
        last_name: generalInfo.lastName,
        contact: generalInfo.contact,
      };
      const apiUrl = `${config.apiBaseUrl}/user/updateProfile`;
      axios.post(apiUrl, payload, {
        headers: {
          'user-id': userId,
          'x-access-token': accessToken,
        },
      })
      .then(response => {
        Swal.fire({
          title: 'Success!',
          text: 'Your profile has been updated successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      })
      .catch(error => {
        console.error('Error saving profile:', error);
        Swal.fire({
          title: 'Error!',
          text: 'There was an error updating your profile. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      });
    }
  };

  const saveKycInfo = () => {
    const userId = Cookies.get('userId');
    const accessToken = Cookies.get('userToken');

    if (userId && accessToken) {
      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('panCard', kycInfo.panNumber);
      formData.append('aadharCard', kycInfo.aadharNumber);
      formData.append('panCardFront', kycInfo.panFrontImage);
      formData.append('panCardBack', kycInfo.panBackImage);
      formData.append('aadharCardFront', kycInfo.aadharFrontImage);
      formData.append('aadharCardBack', kycInfo.aadharBackImage);
 
      const apiUrl = `${config.apiBaseUrl}/user/submitKYC`;
      axios.post(apiUrl, formData, {
        headers: {
          'x-access-token': accessToken,
        },
      })
      .then(response => {
        const successMessage = response.data.message || 'KYC details have been saved successfully.';
        Swal.fire({
          title: 'Success!',
          text: successMessage,
          icon: 'success',
          confirmButtonText: 'OK',
        });
      })
      .catch(error => {
        if (error.response) {
          const statusCode = error.response.status;
          let errorMessage = 'There was an error saving your KYC details. Please try again.';

          if (statusCode === 400) {
            errorMessage = 'Invalid data format. Please check the data and try again.';
          } else if (statusCode === 498) {
            errorMessage = 'KYC is already submitted and cannot be resubmitted at this time.';
          } else {
            errorMessage = error.response.message || errorMessage;
          }

          Swal.fire({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Network error or server not reachable. Please try again later.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      });
    }
  };


  return (
    <div className={`profile-page ${theme}`}>
      <div className="profile-container">
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <div className="account-card">
              <h2>Account Setting</h2>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                <div>
                  <p className="profile-link">My Profile</p>
                </div>
              </Box>
              <hr className="divider" />
              
              <div className="general-info">
                <form>
                  <div className="form-row">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        value={generalInfo.firstName}
                        onChange={handleGeneralInfoChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        value={generalInfo.lastName}
                        onChange={handleGeneralInfoChange}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label>Username</label>
                      <input
                        type="text"
                        placeholder="Username"
                        name="userName"
                        value={generalInfo.userName}
                        onChange={handleGeneralInfoChange}
                        disabled={true}  
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={generalInfo.email}
                        onChange={handleGeneralInfoChange}
                        disabled={true}  
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label>Contact</label>
                      <input
                        type="text"
                        placeholder="Contact"
                        name="contact"
                        value={generalInfo.contact}
                        onChange={handleGeneralInfoChange}
                      />
                    </div>
                  </div>
                </form>
              </div>

              <div className="button-group">
                <Button variant="contained" color="primary" onClick={saveGeneralInfo}>
                  Save 
                </Button>
              </div>
            </div>

            {/* KYC Form Card */}
            <div className="account-card" style={{ marginTop: '20px' }}>
              <h2>KYC Information</h2>
              <hr className="divider" />
              <div className="kyc-info">
                <h4>Complete your KYC</h4>
                <form>
                  <div className="form-group">
                    <label>Aadhar Number</label>
                    <input
                      type="text"
                      placeholder="Aadhar Number"
                      name="aadharNumber"
                      value={kycInfo.aadharNumber}
                      onChange={handleKycChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Pan Number</label>
                    <input
                      type="text"
                      placeholder="PAN Number"
                      name="panNumber"
                      value={kycInfo.panNumber}
                      onChange={handleKycChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Aadhar Front Image</label>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <input
                          type="file"
                          name="aadharFrontImage"
                          onChange={handleKycChange}
                          style={{ width: '100%' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <input
                          type="file"
                          name="aadharBackImage"
                          onChange={handleKycChange}
                          style={{ width: '100%' }}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div className="form-group">
                    <label>PAN Front Image</label>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <input
                          type="file"
                          name="panFrontImage"
                          onChange={handleKycChange}
                          style={{ width: '100%' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <input
                          type="file"
                          name="panBackImage"
                          onChange={handleKycChange}
                          style={{ width: '100%' }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </form>
                <div className="button-group">
                  <Button variant="contained" color="primary" onClick={saveKycInfo}>
                    Save KYC
                  </Button>
                </div>
              </div>
            </div>
          </Grid>

          {/* Right Column - KYC Status */}
          <Grid item xs={12} md={4}>
            <div className={`profile-card ${theme}`}>
              <div className="profile-card-body">
                <div className="profile-avatar-container" style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                  <Avatar style={{ width: '60px', height: '60px', backgroundColor: '#ccc' }}>
                    <PersonIcon style={{ fontSize: '40px', color: '#fff' }} />
                  </Avatar>
                </div>
                <h4>{generalInfo.userName}</h4>
                <div className="kyc-status">
                  <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {isKyc ? (
                      <>
                        <CheckCircle style={{ color: 'green', marginRight: '8px' }} />
                        <span>KYC Completed</span>
                      </>
                    ) : (
                      <>
                        <ErrorOutline style={{ color: 'red', marginRight: '8px' }} />
                        <span>KYC Not Done</span>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Profile;
