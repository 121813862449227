import React, { useState } from "react";
import { FaGoogle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import './Register.css';

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Passwords do not match',
        text: 'Please make sure the password and confirm password fields are the same.',
      });
      return;
    }

    const userData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      userName: formData.userName,
      email: formData.email,
      password: formData.password,
    };

    try {
      const response = await fetch('https://backend.smartcapital.co.in/user/registerUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Registration Successful',
          text: 'You have successfully registered!',
        }).then(() => {
          navigate('/signin');
        });;
      } else {
        if (response.status === 409) {
          Swal.fire({
            icon: 'error',
            title: 'Email Already Exists',
            text: 'The email address you entered is already associated with another account.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Registration Failed',
            text: data.message || 'An error occurred. Please try again.',
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Something went wrong. Please try again later.',
      });
    }
  };


  return (
    <div className="login-container">
      <div className="form-section">
        <h1 className="heading">Join Smart Capital</h1>
        <h3 className="sub-heading">Sign Up For Free</h3>

        <div className="form-wrapper">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                className="input-half"
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                className="input-half"
              />
            </div>

            <input
              type="text"
              name="userName"
              placeholder="userName"
              value={formData.userName}
              onChange={handleChange}
              className="input-full"
            />

            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="input-full"
            />

            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="input-full"
            />

            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="input-full"
            />

            <div className="submit-button-wrapper">
              <button type="submit" className="submit-button">
                Get Started
              </button>
            </div>
          </form>
        </div>

        <div className="or-section">OR</div>

        <div className="social-buttons-wrapper">
          <button className="social-button google-button">
            <FaGoogle className="google-icon" style={{ marginRight: '10px' }} />
            Sign in with Google
          </button>
        </div>
        <div className="account-footer">
          Already have an account? <a href="/signin">Login</a>
        </div>
      </div>

      <div className="image-section">
      </div>
    </div>
  );
};

export default Register;
