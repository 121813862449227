import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton, AppBar, Toolbar } from "@mui/material";
import { ThemeContext } from "../../theme-context";
import axios from "axios";
import './Watchlist.css';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import Cookies from 'js-cookie';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';

const Watchlist = () => {
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [stockData, setStockData] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const colors = {
    primary: {
      400: `var(--primary-${theme}-400)`,
      500: `var(--primary-${theme}-500)`,
    },
    greenAccent: {
      500: `var(--greenAccent-${theme}-500)`,
      600: `var(--greenAccent-${theme}-600)`,
    },
    grey: {
      100: `var(--grey-${theme}-100)`,
      300: `var(--grey-${theme}-300)`,
    },
  };

  useEffect(() => {
    const userId = Cookies.get('userId');
    const accessToken = Cookies.get('userToken');
    console.log(userId, accessToken);

    if (!userId || !accessToken) {
      setError('Missing authentication details');
      setLoading(false);
      return;
    }

    const apiUrl = `${config.apiBaseUrl}/user/getWatchList`;

    axios.get(apiUrl, {
      headers: {
        'user-id': userId,
        'x-access-token': accessToken,
      },
    })
      .then((response) => {
        const instruments = response.data.watchListData?.instruments || [];

        if (!Array.isArray(instruments)) {
          setError('Invalid response format');
          setLoading(false);
          return;
        }

        setStockData(instruments);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching stock data:', error);
        if (error.response?.status === 404) {
          setError('No records found');
        } else {
          setError('Failed to fetch data');
        }
        setLoading(false);
      });
  }, []);

  const renderShimmerRows = () => (
    [...Array(5)].map((_, index) => (
      <TableRow key={index}>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
      </TableRow>
    ))
  );

  const removeStockFromWatchlist = (instrumentToken) => {
    const userId = Cookies.get('userId');
    const accessToken = Cookies.get('userToken');

    if (!userId || !accessToken) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'Missing authentication details',
      });
      return;
    }

    const token = parseInt(instrumentToken, 10);
    if (isNaN(token)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Token',
        text: 'The instrument token is invalid.',
      });
      return;
    }

    const data = {
      instrument_tokens: [token],
    };

    const apiUrl = `${config.apiBaseUrl}/user/removeFromWatchList`;

    axios.post(apiUrl, data, {
      headers: {
        'user-id': userId,
        'x-access-token': accessToken,
      },
    })
      .then(() => {
        setStockData((prevData) => prevData.filter((stock) => stock.instrument_token !== instrumentToken));

        Swal.fire({
          icon: 'success',
          title: 'Stock Removed',
          text: 'The stock has been removed from your watchlist.',
        });
      })
      .catch((error) => {
        console.error('Error removing stock:', error);

        Swal.fire({
          icon: 'error',
          title: 'Failed to Remove Stock',
          text: 'There was an error removing the stock from the watchlist.',
        });
      });
  };

  return (
    <Box m="20px">
      {/* AppBar Section */}
      <AppBar
        position="sticky"
        sx={{
          padding: '6px 20px', 
          boxShadow: 'none',    
          backgroundColor: colors.primary[400], 
          borderRadius: '8px',
        }}
      >
        <Toolbar>
          <Box
            sx={{
              backgroundColor: colors.greenAccent[500], 
              borderRadius: '8px', 
              padding: '5px 10px', 
              display: 'flex',    
              alignItems: 'center', 
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: 'white', 
                fontWeight: 'bold', 
              }}
            >
              My Watchlist
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>


      {/* Watchlist Table */}
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="auto" gap="20px">
        <Box gridColumn="span 12">
          <Box sx={{ width: "100%" }}>
            <Box gridColumn="span 12" display="flex" flexDirection="column" gap={2} sx={{ paddingTop: '20px' }}>
              <Box backgroundColor={colors.primary[400]} p={2} borderRadius={2}>
                <TableContainer component={Paper} elevation={0} sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', marginTop: '15px', marginBottom: '15px' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{
                          color: theme === "light" ? colors.grey[500] : "white",
                          position: 'relative',
                          borderBottom: 'none',
                          lineHeight: '1px',
                        }}>
                          <strong>Trading Symbol</strong>
                        </TableCell>
                        <TableCell sx={{
                          color: theme === "light" ? colors.grey[500] : "white",
                          position: 'relative',
                          borderBottom: 'none',
                          lineHeight: '1px',
                        }}>
                          <strong>Company Name</strong>
                        </TableCell>
                        <TableCell sx={{
                          color: theme === "light" ? colors.grey[500] : "white",
                          position: 'relative',
                          borderBottom: 'none',
                          lineHeight: '1px',
                        }}>
                          <strong>Open</strong>
                        </TableCell>
                        <TableCell sx={{
                          color: theme === "light" ? colors.grey[500] : "white",
                          position: 'relative',
                          borderBottom: 'none',
                          lineHeight: '1px',
                        }}>
                          <strong>Close</strong>
                        </TableCell>
                        <TableCell sx={{
                          color: theme === "light" ? colors.grey[500] : "white",
                          position: 'relative',
                          borderBottom: 'none',
                          lineHeight: '1px',
                        }}>
                          <strong>Current Price</strong>
                        </TableCell>
                        <TableCell sx={{
                          color: theme === "light" ? colors.grey[500] : "white",
                          position: 'relative',
                          borderBottom: 'none',
                          lineHeight: '1px',
                        }}>
                          <strong>Net Change</strong>
                        </TableCell>
                        <TableCell sx={{
                          color: theme === "light" ? colors.grey[500] : "white",
                          position: 'relative',
                          borderBottom: 'none',
                          lineHeight: '1px',
                        }}>
                          <strong>Actions</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? renderShimmerRows() : stockData.map((stock, index) => {
                        const netChangeColor = stock.net_change > 0 ? colors.greenAccent[500] : stock.net_change < 0 ? "#f44336" : colors.grey[900];
                        const currentPriceColor = stock.last_price > stock.ohlc.close ? colors.greenAccent[500] : stock.last_price < stock.ohlc.close ? "#f44336" : colors.grey[900];
                        
                        return (
                          <TableRow key={index} sx={{ cursor: 'pointer', '& td, & th': { borderBottom: 'none' } }} onClick={() => navigate(`/StockChart/${stock.instrument_token}`)}>
                            <TableCell sx={{ color: theme === "light" ? colors.grey[900] : "white", position: 'relative', lineHeight: '1' }}>
                              {stock.tradingsymbol}
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  right: '5%',
                                  width: '90%',
                                  height: '1px',
                                  backgroundColor: theme === "light" ? "grey" : "white",
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ color: theme === "light" ? colors.grey[900] : "white", position: 'relative', lineHeight: '1' }}>
                              {stock.name}
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  right: '5%',
                                  width: '90%',
                                  height: '1px',
                                  backgroundColor: theme === "light" ? "grey" : "white",
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ color: theme === "light" ? colors.grey[900] : "white", position: 'relative', lineHeight: '1' }}>
                              {stock.ohlc.open}
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  right: '5%',
                                  width: '90%',
                                  height: '1px',
                                  backgroundColor: theme === "light" ? "grey" : "white",
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ color: theme === "light" ? colors.grey[900] : "white", position: 'relative', lineHeight: '1' }}>
                              {stock.ohlc.close}
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  right: '5%',
                                  width: '90%',
                                  height: '1px',
                                  backgroundColor: theme === "light" ? "grey" : "white",
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ color: currentPriceColor, position: 'relative', lineHeight: '1' }}>
                              {stock.last_price}
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  right: '5%',
                                  width: '90%',
                                  height: '1px',
                                  backgroundColor: theme === "light" ? "grey" : "white",
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ color: netChangeColor, position: 'relative', lineHeight: '1' }}>
                              {stock.net_change.toFixed(2)}
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  right: '5%',
                                  width: '90%',
                                  height: '1px',
                                  backgroundColor: theme === "light" ? "grey" : "white",
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ color: theme === "light" ? colors.grey[900] : "white", position: 'relative', lineHeight: '1' }}>
                              <DeleteIcon
                                className="remove-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeStockFromWatchlist(stock.instrument_token);
                                }}
                                style={{ cursor: 'pointer', color: '#f44336' }}
                              />
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  right: '5%',
                                  width: '90%',
                                  height: '1px',
                                  backgroundColor: theme === "light" ? "grey" : "white",
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Watchlist;
