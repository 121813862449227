import React, { useContext, useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, TablePagination,
  TextField, Select, MenuItem, Skeleton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../theme-context';
import config from '../../config';
import { useWebSocketData } from '../../Context/WebSocketContext';

const fetchData = async () => {
  try {
    const apiUrl = `${config.apiBaseUrl}/admin/getActiveStock`;
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data.map(item => ({
      id: item.instrument_token, 
      symbol: item.tradingsymbol,  
      exchange: item.exchange,     
      name: item.name,
      tradingsymbol : item.tradingsymbol,
      last_price: item.last_price, 
      net_change: item.net_change,
    }));
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

const StockTable = () => {
  const { theme } = useContext(ThemeContext);
  const { socketData } = useWebSocketData();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchData().then(data => {
      setRows(data);
      setLoading(false);
    });
  }, []);

  const filteredStocks = rows.filter(stock =>
    stock.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
    stock.name.toLowerCase().includes(searchTerm.toLowerCase()) 
  );

  const handlePageChange = (event, newPage) => setCurrentPage(newPage);
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const textColor = theme === 'dark' ? '#fff' : '#000';

  const skeletonRows = Array.from(new Array(rowsPerPage), (_, index) => (
    <TableRow key={index}>
      {[...Array(9)].map((_, i) => ( 
        <TableCell key={i}>
          <Skeleton
            animation="wave"
            sx={{
              bgcolor: theme === 'dark' ? '#777' : '#ddd',
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  ));

  const handleRowClick = (instrumentToken) => {
    navigate(`/StockChart/${instrumentToken}`);
  };

  return (
    <div
      className="container mx-auto p-4 space-y-4"
      style={{
        backgroundColor: theme === 'dark' ? '#000000' : '#f0f0f0',
        color: textColor,
        padding: '16px',
      }}
    >
      <div className="flex justify-between items-center" style={{ paddingBottom: '20px', width: '100%' }}>
        <TextField
          label="Search stocks..."
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            backgroundColor: theme === 'dark' ? '#444' : '#f5f5f5',
            input: { color: textColor },
            label: { color: textColor },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '& .MuiOutlinedInput-root.Mui-focused fieldset': {
              borderColor: 'white',
            },
            width: '250px',
          }}
        />
        <Select
          value={rowsPerPage}
          onChange={handleRowsPerPageChange}
          displayEmpty
          sx={{
            backgroundColor: theme === 'dark' ? '#444' : '#f5f5f5',
            color: textColor,
            marginLeft: '16px',
            minWidth: '120px',
          }}
        >
          <MenuItem value={25}>25 rows</MenuItem>
          <MenuItem value={50}>50 rows</MenuItem>
        </Select>
      </div>

      <TableContainer component={Paper} sx={{ backgroundColor: theme === 'dark' ? '#444444' : '#ffffff' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: textColor }}>Trading Symbol</TableCell>
              <TableCell sx={{ color: textColor }}>Name</TableCell>
              <TableCell sx={{ color: textColor }}>Exchange</TableCell> 
              <TableCell sx={{ color: textColor }}>Last Price</TableCell>
              <TableCell sx={{ color: textColor }}>Change in Points</TableCell>
              <TableCell sx={{ color: textColor }}>Net Change</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? skeletonRows
              : filteredStocks
                  .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                  .map((stock) => {
                    const socketInfo = socketData[stock.id]; 
                    const data = socketInfo || stock;
                    const changeInPoints = data.last_price * (data.net_change / 100); 

                    const changeColor = changeInPoints > 0 ? '#1DB954' : (changeInPoints < 0 ? 'red' : textColor);
                    
                    return (
                      <TableRow key={stock.id} hover onClick={() => handleRowClick(stock.id)} sx={{ cursor: 'pointer' }}>
                        <TableCell sx={{ color: textColor }}>{stock.tradingsymbol}</TableCell>
                        <TableCell sx={{ color: textColor }}>{stock.name}</TableCell>
                        <TableCell sx={{ color: textColor }}>{stock.exchange}</TableCell> 
                        <TableCell sx={{ color: textColor }}>{data.last_price}</TableCell>
                        <TableCell sx={{ color: changeColor }}>
                          {changeInPoints.toFixed(2)} 
                        </TableCell>
                        <TableCell
                          sx={{
                            color:
                              data.net_change > 0
                                ? '#1DB954'
                                : data.net_change < 0
                                ? 'red'
                                : textColor,
                          }}
                        >
                          {data.net_change.toFixed(2)}%
                        </TableCell>
                      </TableRow>
                    );
                  })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="flex justify-end mt-4">
        <TablePagination
          component="div"
          count={filteredStocks.length}
          page={currentPage}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          sx={{
            backgroundColor: theme === 'dark' ? '#333' : '#f9f9f9',
            color: textColor,
          }}
        />
      </div>
    </div>
  );
};

export default StockTable;
