import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import {Card,CardContent,Typography,CircularProgress,TextField,Button,Box,ToggleButtonGroup,ToggleButton,InputAdornment,IconButton } from "@mui/material";
import { ArrowUpward, ArrowDownward, Business, Category, People, Event, LocationOn, Person, Link, Description, AccountBalanceWallet,Lock } from "@mui/icons-material";
import { ThemeContext } from "../../theme-context";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import config from "../../config";
import AddCircleIcon from "@mui/icons-material/AddCircle"; 
import Swal from "sweetalert2";
import { createChart } from "lightweight-charts";


const dummyCompanyDetails = {
  name: "Example Corp",
  ticker: "EXMPL",
  sector: "Technology",
  industry: "Software",
  employees: 1500,
  founded: 2000,
  headquarters: "San Francisco, CA",
  ceo: "Jane Doe",
  website: "www.example.com",
  description: "Example Corp is a leading provider of software solutions.",
};

export default function StockChart() {
  const { theme } = useContext(ThemeContext);
  const [chartPeriod, setChartPeriod] = useState("30minute");
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { instrumentToken } = useParams();
  const [quantity, setQuantity] = useState(0);
  const [orderType, setOrderType] = useState("Market");
  const [limit, setLimit] = useState("");
  const [companyDetails, setCompanyDetails] = useState({ name: "", exchange: "", tradingsymbol: "", lot_size: 0 });
  const chartContainerRef = useRef(null);
  const [depthData, setDepthData] = useState({ buy: [], sell: [] });

  useEffect(() => {
    const fetchStockData = async () => {
      const accessToken = Cookies.get("accessToken");
      try {
        setLoading(true);

        const currentDate = new Date();
        const toDate = new Date(currentDate);
        toDate.setDate(currentDate.getDate() - 9); 

        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          const seconds = date.getSeconds().toString().padStart(2, '0');
          return `${year}-${month}-${day}+${hours}:${minutes}:${seconds}`;
        };

        const fromDateString = formatDate(currentDate);
        const toDateString = formatDate(toDate);

        const apiUrl = `${config.apiBaseUrl}/stock/historicalData`;
        const response = await axios.get(apiUrl, {
          headers: {
            "instrument-token": instrumentToken,
            interval: chartPeriod,
            from: "2024-11-06+09:15:00",  
            to: "2024-12-06+09:20:00",     
            "access-token": accessToken,
          },
        });

        const formattedData = response.data.data.candles.map((candle) => {
          return {
            time: new Date(candle[0]).getTime() / 1000, 
            open: candle[1],
            high: candle[2],
            low: candle[3],
            close: candle[4],
          };
        });

        setStockData(formattedData);
      } catch (error) {
        setError("Failed to fetch stock data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    const fetchCompanyDetails = async () => {
      try {
        const apiUrl = `${config.apiBaseUrl}/stock/getStockByInstrumentToken`;
        const response = await axios.get(apiUrl, {
          headers: { "instrument-token": instrumentToken },
        });

        setCompanyDetails({
          name: response.data.name,
          exchange: response.data.exchange,
          tradingsymbol: response.data.tradingsymbol,
          lot_size: response.data.lot_size || 0,
        });

        setDepthData(response.data.depth || { buy: [], sell: [] });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching company details:", error);
        setError("Error fetching data");
        setLoading(false);
      }
    };


    fetchStockData();
    fetchCompanyDetails();
  }, [chartPeriod, instrumentToken]);

   const { buy, sell } = depthData;

  useEffect(() => {
    if (stockData.length && chartContainerRef.current) {
      const sortedData = stockData.sort((a, b) => a.time - b.time);

      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: 400,
        layout: {
          backgroundColor: "transparent",
          textColor: theme === "dark" ? "#000000" : "#000000",
        },
        grid: {
          vertLines: { color: theme === "dark" ? "#555" : "#ddd" },
          horzLines: { color: theme === "dark" ? "#555" : "#ddd" },
        },
        priceScale: {
          borderColor: theme === "dark" ? "#555" : "#ddd",
        },
        crosshair: {
          vertLine: {
            color: theme === "dark" ? "#555" : "#ddd",
          },
          horzLine: {
            color: theme === "dark" ? "#555" : "#ddd",
          },
        },
      });

      const candlestickSeries = chart.addCandlestickSeries({
        upColor: "green",
        borderUpColor: "green",
        wickUpColor: "green",
        downColor: "red",
        borderDownColor: "red",
        wickDownColor: "red",
      });

      candlestickSeries.setData(sortedData); 

      return () => chart.remove();
    }
  }, [stockData, theme]);

  const handleAddToWatchlist = async () => {
    const userId = Cookies.get("userId");
    const userToken = Cookies.get("userToken");
    const instrumentTokenNumber = Number(instrumentToken);

    try {
      const apiUrl = `${config.apiBaseUrl}/user/addToWatchList`;
      const response = await axios.post(
        apiUrl,
        {
          instrument_tokens: [instrumentTokenNumber],
        },
        {
          headers: {
            "user-id": userId,
            "x-access-token": userToken,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Success", "Stock added to watchlist!", "success");
      } else {
        Swal.fire("Error", "Failed to add stock to watchlist.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Error: Unable to add stock to watchlist.", "error");
    }
  };

  const handleBuy = async () => {
    try {
      const userId = Cookies.get('userId');
      const userToken = Cookies.get('userToken');
      const latestPrice = stockData[stockData.length - 1]?.close || limit;

      const orderData = {
        userId: userId,
        instrument_token: instrumentToken,
        symbol: companyDetails.tradingsymbol,
        orderType: orderType.toLowerCase(),
        quantity: quantity,
        price: limit || latestPrice,
      };

      const response = await axios.post(`${config.apiBaseUrl}/order/buy`, orderData, {
        headers: {
          'x-access-token': userToken,
        },
      });

      if (response.data && response.data.message) {
        Swal.fire({
          title: 'Success!',
          text: response.data.message,  
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'An unexpected error occurred while placing the buy order.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }

    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: error.response?.data?.message || 'Failed to place buy order. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleSell = async () => {
    try {
      const userId = Cookies.get('userId');
      const userToken = Cookies.get('userToken');
      const latestPrice = stockData[stockData.length - 1]?.close || limit;

      const orderData = {
        userId: userId,
        instrument_token: instrumentToken,
        symbol: companyDetails.tradingsymbol,
        orderType: orderType.toLowerCase(),
        quantity: quantity,
        price: limit || latestPrice,
      };

      const response = await axios.post(`${config.apiBaseUrl}/order/sell`, orderData, {
        headers: {
          'x-access-token': userToken,
        },
      });

      if (response.data && response.data.message) {
        Swal.fire({
          title: 'Success!',
          text: response.data.message,  
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'An unexpected error occurred while placing the sell order.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }

    } catch (error) {
      if (error.response) {
        if (error.response.status === 428) {
          Swal.fire({
            title: 'Error!',
            text: error.response.data.message || 'Insufficient Stock Quantity Available',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: error.response?.data?.message || 'Failed to place sell order. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Network error or request timeout. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  if (loading) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme === "dark" ? "#121212" : "#f5f5f5",
        }}
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginLeft: "16px" }}>
          Loading Stock Data...
        </Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme === "dark" ? "#121212" : "#f5f5f5",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </div>
    );
  }

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: theme === "dark" ? "#121212" : "#f5f5f5",
        color: theme === "dark" ? "#ffffff" : "#000000",
        padding: "16px",
      }}
    >
      <div style={{ display: "grid", gridTemplateColumns: "70% 30%", gap: "16px", marginTop: "16px" }}>
        {/* Stock Price Chart Card */}
        <Card style={{ backgroundColor: theme === "dark" ? "#181818" : "#fff", color: theme === "dark" ? "#fff" : "#000" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px" }}>
            <div>
              <Typography variant="h5">
                {companyDetails.name} ({companyDetails.exchange})
              </Typography>
              <Typography variant="subtitle1">{companyDetails.tradingsymbol}</Typography>
            </div>
            <div style={{ textAlign: "right", display: "flex", alignItems: "center" }}>
              <Typography variant="h4">₹{stockData[stockData.length - 1]?.close.toFixed(2)}</Typography>
              <div style={{ display: "flex", alignItems: "center", fontSize: "1.5rem" }}>
                {stockData[stockData.length - 1]?.close > stockData[stockData.length - 2]?.close ? (
                  <ArrowUpward style={{ color: "green" }} />
                ) : (
                  <ArrowDownward style={{ color: "red" }} />
                )}
              </div>
              <IconButton 
                onClick={handleAddToWatchlist} 
                style={{ marginLeft: "16px", color: theme === "dark" ? "#fff" : "#000" }}
              >
                <AddCircleIcon />
              </IconButton>
            </div>
          </div>
          <div
            ref={chartContainerRef}
            style={{
              width: "100%",
              height: "400px",
            }}
          ></div>
        </Card>

        {/* Order Panel */}
        <Card
        style={{
          backgroundColor: theme === "dark" ? "#181818" : "#fff",
          color: theme === "dark" ? "#fff" : "#000",
        }}
        >
        <div style={{ padding: "16px" }}>
          <Typography variant="h6" gutterBottom>
            Order Panel
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
            <TextField
              label="Quantity"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              fullWidth
              InputLabelProps={{
                style: {
                  color: theme === "dark" ? "#fff" : "#000", 
                },
              }}
              InputProps={{
                style: {
                  color: theme === "dark" ? "#fff" : "#000", 
                  backgroundColor: theme === "dark" ? "#282828" : "#f5f5f5", 
                },
              }}
              variant="outlined"
              style={{
                width: "48%", 
              }}
              inputProps={{
                style: {
                  color: theme === "dark" ? "#fff" : "#000", 
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme === "dark" ? "#fff" : "#000", 
                  },
                  "&:hover fieldset": {
                    borderColor: theme === "dark" ? "#fff" : "#000", 
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme === "dark" ? "#fff" : "#000", 
                  },
                },
              }}
            />
            <TextField
              label="Limit"
              type="number"
              value={limit}
              onChange={(e) => setLimit(e.target.value)}
              fullWidth
              disabled={orderType === "Market"} 
              InputLabelProps={{
                style: {
                  color: theme === "dark" ? "#fff" : "#000", 
                },
              }}
              InputProps={{
                style: {
                  color: theme === "dark" ? "#fff" : "#000", 
                  backgroundColor: theme === "dark" ? "#282828" : "#f5f5f5", 
                },
                startAdornment: orderType === "Market" && (
                  <InputAdornment position="start">
                    <Lock style={{ color: theme === "dark" ? "#fff" : "#000" }} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              style={{
                width: "48%", 
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme === "dark" ? "#fff" : "#000", 
                  },
                  "&:hover fieldset": {
                    borderColor: theme === "dark" ? "#fff" : "#000", 
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme === "dark" ? "#fff" : "#000", 
                  },
                },
              }}
            />
          </Box>

          {/* Order Type Toggle */}
          <ToggleButtonGroup
            value={orderType}
            exclusive
            onChange={(e, newOrderType) => setOrderType(newOrderType)}
            fullWidth
            style={{
              marginBottom: "16px",
              backgroundColor: theme === "dark" ? "#282828" : "#f5f5f5",
              color: theme === "dark" ? "#fff" : "#000",
              borderColor: theme === "dark" ? "#fff" : "#000",
            }}
          >
            <ToggleButton
              value="Market"
              selected={orderType === "Market"}
              style={{
                borderColor: theme === "dark" ? "#fff" : "#000",
                backgroundColor: orderType === "Market"
                  ? (theme === "dark" ? "#444" : "#d4d4d4")
                  : (theme === "dark" ? "#333" : "#fff"), 
                color: orderType === "Market" ? "#000" : (theme === "dark" ? "#fff" : "#000"), 
              }}
            >
              Market Order
            </ToggleButton>

            <ToggleButton
              value="Limit"
              selected={orderType === "Limit"}
              style={{
                borderColor: theme === "dark" ? "#fff" : "#000",
                backgroundColor: orderType === "Limit"
                  ? (theme === "dark" ? "#444" : "#d4d4d4") 
                  : (theme === "dark" ? "#333" : "#fff"), 
                color: orderType === "Limit" ? "#000" : (theme === "dark" ? "#fff" : "#000"), 
              }}
            >
              Limit Order
            </ToggleButton>
          </ToggleButtonGroup>

          <Typography variant="body1">
            Total Stock: {companyDetails.lot_size > 0 ? quantity * companyDetails.lot_size : quantity}
          </Typography>

          {/* Buy/Sell Buttons */}
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              onClick={handleBuy}
              fullWidth
              style={{
                marginRight: "8px",
                backgroundColor: "#4caf50", 
                color: "#fff", 
                borderColor: "#388e3c", 
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")} 
              onMouseOut={(e) => (e.target.style.backgroundColor = "#4caf50")} 
            >
              Buy
            </Button>

            {/* Sell Button - Red color */}
            <Button
              variant="contained"
              onClick={handleSell}
              fullWidth
              style={{
                backgroundColor: "#f44336", 
                color: "#fff", 
                borderColor: "#d32f2f", 
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#e53935")} 
              onMouseOut={(e) => (e.target.style.backgroundColor = "#f44336")} 
            >
              Sell
            </Button>
          </Box>
        </div>
      </Card>
      </div>
      {/* Company Details Panel */}
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px", marginTop: "16px" }}>
        <Card style={{ backgroundColor: theme === "dark" ? "#181818" : "#fff", color: theme === "dark" ? "#fff" : "#000" }}>
          <Typography variant="h5" style={{ display: "flex", alignItems: "center", marginTop: '10px', marginLeft: '10px' }}>
            <Business style={{ marginRight: "8px" }} /> Company Information
          </Typography>
          <CardContent style={{ paddingTop: "24px" }}>
            <dl style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px" }}>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <Category style={{ marginRight: "8px" }} /> Sector
                </Typography>
                <Typography>{dummyCompanyDetails.sector}</Typography>
              </div>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <People style={{ marginRight: "8px" }} /> Employees
                </Typography>
                <Typography>{dummyCompanyDetails.employees}</Typography>
              </div>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <Event style={{ marginRight: "8px" }} /> Founded
                </Typography>
                <Typography>{dummyCompanyDetails.founded}</Typography>
              </div>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <LocationOn style={{ marginRight: "8px" }} /> Headquarters
                </Typography>
                <Typography>{dummyCompanyDetails.headquarters}</Typography>
              </div>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <Person style={{ marginRight: "8px" }} /> CEO
                </Typography>
                <Typography>{dummyCompanyDetails.ceo}</Typography>
              </div>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <Link style={{ marginRight: "8px" }} /> Website
                </Typography>
                <Typography>
                  <a href={dummyCompanyDetails.website} target="_blank" rel="noopener noreferrer" style={{ color: theme === "dark" ? "#1e90ff" : "#007bff" }}>
                    {dummyCompanyDetails.website}
                  </a>
                </Typography>
              </div>
            </dl>
            <div style={{ marginTop: "16px" }}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <Description style={{ marginRight: "8px" }} /> Company Description
              </Typography>
              <Typography>{dummyCompanyDetails.description}</Typography>
            </div>
          </CardContent>
        </Card>

        {/* Financial Metrics */}
        <Card style={{ backgroundColor: theme === "dark" ? "#181818" : "#fff", color: theme === "dark" ? "#fff" : "#000" }}>
          <Typography variant="h5" style={{ display: "flex", alignItems: "center", marginTop: '10px', marginLeft: '10px' }}>
            <AccountBalanceWallet style={{ marginRight: "8px" }} /> Stock Order Book Depth
          </Typography>
          <CardContent style={{ paddingTop: "24px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>
                {/* <Typography variant="subtitle1" style={{ fontWeight: "bold", marginBottom: '10px' }}>
                  Buy Depth
                </Typography> */}
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "16px" }}>
                  <div style={{ fontWeight: "bold" }}>Price</div>
                  <div style={{ fontWeight: "bold" }}>Quantity</div>
                  <div style={{ fontWeight: "bold" }}>Orders</div>
                  {buy.map((order, index) => (
                    <React.Fragment key={index}>
                      <div style={{ color: "green" }}>{order.price}</div>
                      <div style={{ color: "green" }}>{order.quantity}</div>
                      <div style={{ color: "green" }}>{order.orders}</div>
                    </React.Fragment>
                  ))}
                </div>
              </div>

              {/* Sell Depth */}
              <div style={{ width: "48%" }}>
                {/* <Typography variant="subtitle1" style={{ fontWeight: "bold", marginBottom: '10px' }}>
                  Sell Depth
                </Typography> */}
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "16px" }}>
                  <div style={{ fontWeight: "bold" }}>Price</div>
                  <div style={{ fontWeight: "bold" }}>Quantity</div>
                  <div style={{ fontWeight: "bold" }}>Orders</div>
                  {sell.map((order, index) => (
                    <React.Fragment key={index}>
                      <div style={{ color: "red" }}>{order.price}</div>
                      <div style={{ color: "red" }}>{order.quantity}</div>
                      <div style={{ color: "red" }}>{order.orders}</div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
